export const mapIntervalValue = (
  recurPeriod: 'Daily' | 'Weekly' | 'Yearly' | 'Monthly',
  interval: number
) => {
  if (recurPeriod === 'Daily') {
    return {
      interval1: interval,
      interval2: 0,
      interval3: 0,
    };
  }
  if (recurPeriod === 'Weekly') {
    return {
      interval1: interval,
      interval2: 0,
      interval3: 0,
    };
  }
  if (recurPeriod === 'Yearly') {
    return {
      interval1: interval,
      interval2: 0,
      interval3: 0,
    };
  }
  if (recurPeriod === 'Monthly') {
    return {
      interval1: interval,
      interval2: 0,
      interval3: 0,
    };
  }
};

export const getWeekLabels = () => [
  {
    label: 'First',
    value: 1,
  },
  {
    label: 'Second',
    value: 2,
  },

  {
    label: 'Third',
    value: 3,
  },

  {
    label: 'Fourth',
    value: 4,
  },

  {
    label: 'Last',
    value: 0,
  },
];

export const getMonthlyNameOptions = () => [
  {
    label: 'January',
    value: 1,
  },
  {
    label: 'February',
    value: 2,
  },
  {
    label: 'March',
    value: 3,
  },
  {
    label: 'April',
    value: 4,
  },
  {
    label: 'May',
    value: 5,
  },
  {
    label: 'June',
    value: 6,
  },
  {
    label: 'July',
    value: 7,
  },
  {
    label: 'August',
    value: 8,
  },
  {
    label: 'September',
    value: 9,
  },
  {
    label: 'October',
    value: 10,
  },
  {
    label: 'November',
    value: 11,
  },
  {
    label: 'December',
    value: 12,
  },
];

export const getMonthlyOptions = () => [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '12',
    value: 12,
  },
  {
    label: '18',
    value: 18,
  },
];

export const getMonthlyDayOptions = () => [
  {
    label: '1st',
    value: 1,
  },
  {
    label: '2nd',
    value: 2,
  },
  {
    label: '3rd',
    value: 3,
  },
  {
    label: '4th',
    value: 4,
  },
  {
    label: '5th',
    value: 5,
  },
  {
    label: '6th',
    value: 6,
  },
  {
    label: '7th',
    value: 7,
  },
  {
    label: '8th',
    value: 8,
  },
  {
    label: '9th',
    value: 9,
  },
  {
    label: '10th',
    value: 10,
  },
  {
    label: '11th',
    value: 11,
  },
  {
    label: '12th',
    value: 12,
  },
  {
    label: '13th',
    value: 13,
  },
  {
    label: '14th',
    value: 14,
  },
  {
    label: '15th',
    value: 15,
  },
  {
    label: '16th',
    value: 16,
  },
  {
    label: '17th',
    value: 17,
  },
  {
    label: '18th',
    value: 18,
  },
  {
    label: '19th',
    value: 19,
  },
  {
    label: '20th',
    value: 20,
  },
  {
    label: '21st',
    value: 21,
  },
  {
    label: '22nd',
    value: 22,
  },
  {
    label: '23rd',
    value: 23,
  },
  {
    label: '24th',
    value: 7,
  },
  {
    label: '25th',
    value: 25,
  },
  {
    label: '26th',
    value: 26,
  },
  {
    label: '27th',
    value: 27,
  },
  {
    label: '28th',
    value: 28,
  },
  {
    label: '29th',
    value: 29,
  },
  {
    label: '30th',
    value: 30,
  },
  {
    label: '31st',
    value: 31,
  },
];

export const getDailyWeeklyOptions = () => [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20,
  },
];

export const getDaysOfWeek = () => [
  {
    label: 'Sunday',
    value: 0,
  },
  {
    label: 'Monday',
    value: 1,
  },
  {
    label: 'Tuesday',
    value: 2,
  },

  {
    label: 'Wednesday',
    value: 3,
  },

  {
    label: 'Thursday',
    value: 4,
  },

  {
    label: 'Friday',
    value: 5,
  },

  {
    label: 'Saturday',
    value: 6,
  },
];

interface GetIntervalValuesParams {
  recurPeriod: string;
  dailyInterval1: number;
  weeklyInterval1: number;
  weeklyInterval2: number;
  monthlyInterval1: number;
  monthlyInterval2: number;
  monthlyInterval3: number;
  yearlyInterval1: number;
  yearlyInterval2: number;
  yearlyInterval3: number;
}

export const getIntervalValues = (
  values: GetIntervalValuesParams
): {
  interval1: number;
  interval2: number;
  interval3: number;
} => {
  if (values.recurPeriod === 'Daily') {
    return {
      interval1: values.dailyInterval1,
      interval2: 0,
      interval3: 0,
    };
  }
  if (values.recurPeriod === 'Weekly') {
    return {
      interval1: values.weeklyInterval1,
      interval2: values.weeklyInterval2,
      interval3: 0,
    };
  }
  if (values.recurPeriod === 'Monthly') {
    return {
      interval1: values.monthlyInterval1,
      interval2: values.monthlyInterval2,
      interval3: values.monthlyInterval3,
    };
  }

  return {
    interval1: values.yearlyInterval1,
    interval2: values.yearlyInterval2,
    interval3: values.yearlyInterval3,
  };
};
