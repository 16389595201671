import { Scheduler } from '@aldabil/react-scheduler';
import { ScheduledWorkCard } from './ScheduledWorkCard';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ICalendarChanges,
  ICalendarDateRange,
  ICalendarView,
  IDayViewSettings,
  IMonthViewSettings,
  IScheduledWorkDragChangeDetails,
  ICalendarEventDetail,
  IWeekViewSettings,
  ScheduledWorkCalendarEvent,
  ScheduledRendererWorkCalendarEvent,
} from '../../../../../models';
import { FC, useEffect, useMemo, useState } from 'react';
import { convertScheduledWorkToCalendarEvent } from '../../../../../helpers';
import { UserAvatar } from '../../../../user-avatar';
import { Button, Typography } from '@mui/material';
import { ProcessedEvent, SchedulerProps } from '@aldabil/react-scheduler/types';
import {
  DEFAULT_DAY_SETTINGS,
  DEFAULT_MONTH_SETTINGS,
  DEFAULT_WEEK_SETTINGS,
} from '../../../config';
import { WeekDays } from '@aldabil/react-scheduler/views/Month';
import { v4 as uuidv4 } from 'uuid';
import { IColorSetMap } from '../../../../../models/colors';

export interface ScheduledWorkProps {
  userColorMap?: IColorSetMap;
  scheduledWorkItems: ICalendarEventDetail[];
  dateRange: ICalendarDateRange;
  onChange?: (change: IScheduledWorkDragChangeDetails) => unknown;
  changes?: ICalendarChanges;
  view: ICalendarView;
  daySettings?: IDayViewSettings;
  workWeekSettings?: IWeekViewSettings;
  weekSettings?: IWeekViewSettings;
  monthSettings?: IMonthViewSettings;
}

export const ScheduledWork: FC<ScheduledWorkProps> = ({
  userColorMap,
  scheduledWorkItems,
  onChange,
  dateRange,
  view,
  daySettings = DEFAULT_DAY_SETTINGS,
  workWeekSettings = DEFAULT_WEEK_SETTINGS,
  monthSettings = DEFAULT_MONTH_SETTINGS,
}) => {
  const classes = useStyles();
  const [schedulerDropChange, setSchedulerDropChange] = useState<IScheduledWorkDragChangeDetails>();

  useEffect(() => {
    if (!onChange || !schedulerDropChange) {
      return;
    }
    setSchedulerDropChange(undefined);
    onChange(schedulerDropChange);
  }, [schedulerDropChange, onChange]);

  // this is a workaround to a stale state problem of the scheduler plugin. See https://github.com/aldabil21/react-scheduler/issues/147
  const triggerChangeHack = (change: IScheduledWorkDragChangeDetails) => {
    setSchedulerDropChange(change);
  };

  const events = useMemo(
    () => scheduledWorkItems.map(convertScheduledWorkToCalendarEvent),
    [scheduledWorkItems]
  );

  const onEventDrop = async (
    droppedOn: Date,
    updatedEvent: ProcessedEvent,
    originalEvent: ProcessedEvent
  ) => {
    const event = updatedEvent as ScheduledWorkCalendarEvent;
    const change: IScheduledWorkDragChangeDetails = {
      scheduledWork: event.scheduledWork,
      startTime: event.start,
      endTime: event.end,
    };

    // trigger on change after the calendar is done updating its internal state (via setTimeout)
    setTimeout(() => {
      triggerChangeHack(change);
    }, 1);

    return updatedEvent;
  };

  const { schedulerProps, renderKey } = useMemo(() => {
    const props: Partial<SchedulerProps> = {
      navigation: false,
      disableViewNavigator: true,
      events,
      onEventDrop,
      eventRenderer: event => {
        const { scheduledWork } = event as ScheduledRendererWorkCalendarEvent;
        return (
          <ScheduledWorkCard
            workDetail={scheduledWork}
            colorSet={userColorMap?.[scheduledWork?.userInformation?.userId]}
          />
        );
      },
      viewerExtraComponent: (event, fields) => {
        const { scheduledWork } = fields as ScheduledWorkCalendarEvent;
        const { userInformation } = scheduledWork;
        const { username, avatarUrl, backgroundColor } = userInformation;
        return (
          <div className={classes.popoverContainer}>
            <Typography>{scheduledWork.event.address?.street}</Typography>
            <div className={classes.userAvatarContainer}>
              <UserAvatar
                className={classes.userAvatar}
                src={avatarUrl || undefined}
                userName={username}
                backgroundColor={backgroundColor}
              />
              <Typography variant="caption">{username}</Typography>
            </div>
          </div>
        );
      },
    };

    switch (view) {
      case ICalendarView.Day:
        props.view = 'day';
        props.selectedDate = dateRange.startDate;
        props.day = {
          startHour: daySettings.startHour,
          endHour: daySettings.endHour,
          step: daySettings.step,
        };
        break;
      case ICalendarView.Month:
        props.view = 'month';
        props.month = {
          weekDays: monthSettings.weekdays,
          weekStartOn: monthSettings.weekStartOn,
          startHour: monthSettings.startHour,
          endHour: monthSettings.endHour,
        };
        break;
      case ICalendarView.Week:
      case ICalendarView.WorkWeek:
        props.view = 'week';
        props.week = {
          weekDays: workWeekSettings.weekdays.map(weekday => weekday - 1) as WeekDays[],
          weekStartOn: workWeekSettings.weekStartOn,
          startHour: workWeekSettings.startHour,
          endHour: workWeekSettings.endHour,
          step: workWeekSettings.step,
          disableGoToDay: false,
          navigation: false,
          cellRenderer: ({ height, start, onClick, ...props }) => {
            return (
              <Button
                style={{
                  height: '100%',
                  background: 'transparent',
                  cursor: 'default',
                }}
                disableRipple
                {...props}
                data-testid='close-button'
              ></Button>
            );
          },
        };
        break;
    }

    return { schedulerProps: props as SchedulerProps, renderKey: uuidv4() };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySettings, monthSettings, workWeekSettings, view, events, dateRange]);

  return (
    <div className={classes.schedulerContainer}>
      <Scheduler key={renderKey} {...schedulerProps} />
    </div>
  );
};

const MOBILE_MEDIA_QUERY = '@media (max-width: 576px)';

const useStyles = makeStyles((theme: Theme) => ({
  schedulerContainer: {
    width: '100%',
    '& .css-96e9fn > .css-12aktbw': {
      '& .css-9ba4yw': {
        top: -2,
        [MOBILE_MEDIA_QUERY]: {
          top: 64,
        },
        '& .rs__header': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        '& div': {
          display: 'flex',
          flexDirection: 'column-reverse',
          alignItems: 'center',
          padding: 0,
        },
        '& p': {
          [MOBILE_MEDIA_QUERY]: {
            fontSize: '11px !important',
          },
        },
      },
      '& .css-1ua2f74 > .rs__header > span': {
        lineHeight: 1.2,
      },
    },
    '&& > div[data-testid="rs-wrapper"] > div:first-child': {
      '@media print': {
        display: 'none !important',
      },
    },
    '&& > div[data-testid="rs-wrapper"] > div[data-testid="grid"] > div': {
      '@media print': {
        position: 'static',
      },
    },
    '& .css-96e9fn > .css-12aktbw > .css-9ba4yw > .rs__header > div ': {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      padding: 0,
      '& p:nth-of-type(2)': {
        fontWeight: '600 !important',
        color: theme.palette.primary.main,
        fontSize: '16px !important',
        [MOBILE_MEDIA_QUERY]: {
          fontSize: '11px !important',
        },
      },
    },
  },
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 5,
  },
  userAvatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 5,
  },
  userAvatar: {
    height: '30px',
    width: '30px',
    fontSize: '10px',
    marginRight: 0,
  },
}));
