import { Typography, Card, Box, Button, darken } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import { UserAvatar } from '../../../user-avatar';
import { ICalendarItemUser, ICalendarView } from '../../../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck, faRepeat, faWrench } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { IColorSet } from '../../../../models/colors';

interface EmployeeStatusCardProps {
  calendarItemUser: ICalendarItemUser;
  calendarDate: string;
  colorSet?: IColorSet;
  onNameClick?: () => unknown;
  onViewClick?: () => unknown;
  view: ICalendarView;
}

export const EmployeeStatusCard: FC<EmployeeStatusCardProps> = ({
  calendarItemUser,
  colorSet,
  onNameClick,
  onViewClick,
  view,
}) => {
  const { userInformation, scheduledServiceCount, repairCount, scheduledTaskCount } =
    calendarItemUser;

  const { username, avatarUrl, backgroundColor, foregroundColor } = userInformation;

  const classes = useStyles({ backgroundColor, view, foregroundColor });

  return (
    <Card className={clsx(classes.employeeStatusCard, 'print--avoid-break')}>
      <Box className={classes.employeeStatusCardInfo}>
        <UserAvatar
          className={clsx(classes.userAvatar, 'print--none')}
          src={avatarUrl || undefined}
          userName={username}
        />
        <Button className={classes.employeeName} onClick={onNameClick} variant="text" data-testid='username-button'>
          {username}
        </Button>
      </Box>
      <Box className={classes.employeeStatusCardContent}>
        <Box>
          {!!scheduledServiceCount && (
            <Typography className={classes.serviceStyles} data-testid='service-count'>
              <FontAwesomeIcon icon={faRepeat} size="sm" />
              {`${scheduledServiceCount} Service${scheduledServiceCount > 1 ? 's' : ''}`}
            </Typography>
          )}
          {!!repairCount && (
            <Typography className={classes.serviceStyles} data-testid='repair-count'>
              <FontAwesomeIcon icon={faWrench} size="sm" />
              {`${repairCount} Visit${repairCount > 1 ? 's' : ''}`}
            </Typography>
          )}
          {!!scheduledTaskCount && (
            <Typography className={classes.serviceStyles} data-testid='task-count'>
              <FontAwesomeIcon icon={faListCheck} size="sm" />
              {`${scheduledTaskCount} Task${scheduledTaskCount > 1 ? 's' : ''}`}
            </Typography>
          )}
        </Box>
        <Button className={clsx(classes.viewButton, 'print--none')} onClick={onViewClick} data-testid='view-button'>
          VIEW
        </Button>
      </Box>
    </Card>
  );
};

const LAPTOP_L_MEDIA_QUERY = '@media (max-width: 1440px)';
const LAPTOP_MEDIA_QUERY = '@media (max-width: 1250px)';
const MOBILE_MEDIA_QUERY = '@media (max-width: 576px)';

const useStyles = makeStyles<
  Theme,
  { backgroundColor: string; view: ICalendarView; foregroundColor: string }
>((theme: Theme) => ({
  employeeStatusCard: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    height: 'fit-content',
    gap: 5,
    borderStyle: 'solid',
    borderWidth: '0px 0px 0px 8px',
    borderRadius: 6,
    borderColor: ({ backgroundColor }) => backgroundColor,
    [LAPTOP_MEDIA_QUERY]: {
      borderWidth: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
            return '1px 1px 1px 1px';
          default:
            return;
        }
      },
    },
    [MOBILE_MEDIA_QUERY]: {
      borderWidth: ({ view }) => {
        switch (view) {
          case ICalendarView.WorkWeek:
            return '1px 1px 1px 1px';
          default:
            return;
        }
      },
    },
    '@media print': {
      boxShadow: 'none !important',
      marginBottom: theme.spacing(1),
      borderWidth: '1px 1px 1px 8px',
    },
  },
  employeeStatusCardInfo: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 5,
    padding: 10,
    [LAPTOP_MEDIA_QUERY]: {
      flexDirection: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'column';
          default:
            return;
        }
      },
      padding: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 5;
          default:
            return;
        }
      },
    },
    [MOBILE_MEDIA_QUERY]: {
      textAlign: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'center';
          default:
            return;
        }
      },
    },
    '@media print': {
      boxShadow: 'none !important',
    },
  },
  employeeName: {
    fontSize: 14,
    fontWeight: 600,
    textDecoration: 'underline',
    color: ({ backgroundColor }) => backgroundColor,
    lineHeight: 1.2,
    textAlign: 'left',
    [LAPTOP_MEDIA_QUERY]: {
      textAlign: 'center',
    },
    [MOBILE_MEDIA_QUERY]: {
      fontSize: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 12;
          default:
            return;
        }
      },
    },

    '@media print': {
      position: 'static',
      color: `${theme.palette.common.black} !important`,
    },
  },
  userAvatar: {
    height: '45px',
    width: '45px',
    fontSize: '14px',
    margin: 0,
    [LAPTOP_L_MEDIA_QUERY]: {
      height: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 35;
          default:
            return;
        }
      },
      width: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 35;
          default:
            return;
        }
      },
    },
    [LAPTOP_MEDIA_QUERY]: {
      height: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 25;
          default:
            return;
        }
      },
      width: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 25;
          default:
            return;
        }
      },
    },
  },
  employeeStatusCardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: 5,
    gap: 5,
    backgroundColor: '#F2F2F2',
    [LAPTOP_MEDIA_QUERY]: {
      flexDirection: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'column';
          default:
            return;
        }
      },
    },
    [MOBILE_MEDIA_QUERY]: {
      textAlign: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'center';
          default:
            return;
        }
      },
    },
  },
  serviceStyles: {
    fontSize: 13,
    paddingLeft: 3,
    color: theme.palette.primary.main,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    gap: 5,

    [LAPTOP_MEDIA_QUERY]: {
      fontSize: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 12;
          default:
            return;
        }
      },
    },
    [MOBILE_MEDIA_QUERY]: {
      textAlign: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'center';
          default:
            return;
        }
      },
    },
  },
  viewButton: {
    fontSize: 12,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    borderRadius: theme.shape.borderRadius,
    fontWeight: 600,
    color: ({ foregroundColor }) => foregroundColor,
    width: '70px',
    height: '25px',
    '&:hover': {
      backgroundColor: ({ backgroundColor }) => darken(backgroundColor, 0.2),
      color: ({ foregroundColor }) => foregroundColor,
    },
    [LAPTOP_L_MEDIA_QUERY]: {
      width: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 'auto';
          default:
            return;
        }
      },
    },
    [MOBILE_MEDIA_QUERY]: {
      fontSize: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 10;
          default:
            return;
        }
      },
      height: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 20;
          default:
            return;
        }
      },
      minWidth: ({ view }) => {
        switch (view) {
          case ICalendarView.Week:
          case ICalendarView.Month:
          case ICalendarView.WorkWeek:
            return 45;
          default:
            return;
        }
      },
    },
  },
}));
